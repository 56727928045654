<template>
  <div>
    <three-comp />
    <div class="cage " :style="{backdropFilter: 'blur('+(blur+0)+'px) brightness(2) contrast(1) saturate(.85) hue-rotate('+hue+'deg)'}"></div>
  </div>
</template>

<script>
import ThreeComp from "../components/ThreeComp.vue";

export default {
    name: "HeadeExperiment",
    components: {
        ThreeComp,
    },
    data(){
        return{
            hue:0,
            blur:2
        }
    },
    
    mounted(){
        this.animateHue()
        let scrollBkp;
        let paper = document.querySelector('html');
        window.addEventListener("scroll", (ev) => {
            let scrollHeight = paper.scrollHeight - paper.clientHeight;
            let percentScroll = paper.scrollTop/scrollHeight;
            //this.hue+=5;
            paper.scrollTop>scrollBkp?this.blur+=.5:this.blur-=.5;
            this.blur<2?this.blur=2:this.blur;
            paper.scrollTop<10?this.blur=2:this.blur;
            scrollBkp = paper.scrollTop;
        });

    },
    methods:{
        animateHue(){
            window.requestAnimationFrame(() => {
                this.hue++;
                this.animateHue()
            });
        }
    }
}
</script>
<style scoped>
.cage{
    /*backdrop-filter: blur(2px) brightness(3) contrast(10) saturate(0);
    backdrop-filter: blur(2px) brightness(2) contrast(2) saturate(.1) hue-rotate(200deg);*/    
    background: #15022B;
    background: -webkit-linear-gradient(bottom right, #15022B, #4A4685);
    background: -moz-linear-gradient(bottom right, #15022B, #4A4685);
    background: linear-gradient(to top left, #15022B, #4A4685);
    opacity: .4;
    height: 100vh;
    width: 100%;
    z-index: 10;
    position: fixed;
    top:0;
    mix-blend-mode: saturation;
}
</style>